import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccessService } from 'app/services/access.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  constructor(private fb: FormBuilder, private as: AccessService, private router: Router) {}

  ngOnInit() {
    if (this.as.userAuthenticated()) {
      this.router.navigate(['/']);
    } else {
      this.createForm();
    }
  }

  createForm() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    })
  }

  doLogin() {
    this.as.doLogin(this.loginForm.value).subscribe((data: any) => {
      const username = this.loginForm.get('username').value;
      let expireDate = new Date();
      expireDate.setSeconds(expireDate.getSeconds() + data.expires_in);
      localStorage.setItem('flint', JSON.stringify({username, token: data.access_token, expires: expireDate}));
      this.router.navigate(['private']);
    }, (err) => {
      console.log(err);
      alert('Usuário e/ou senha inválidos!');
    });
  }

  reset() {
    this.loginForm.reset();
  }

}
