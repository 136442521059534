import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  uri = `${environment.serverHostUrl}/api/v1/access`;

  constructor(private http: HttpClient) { }

  doLogin(user) {
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa('FLINT:FL1NT@S')
    });

    const body = `grant_type=password&username=${user.username}&password=${user.password}`;

    return this.http.post(`${this.uri}/oauth/token`, body, {headers});

  }

  refreshToken(token: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa('FLINT:FL1NT@S')
    });

    const body = `grant_type=refresh_token&refresh_token=${token}`;

    return this.http.post(`${this.uri}/oauth/token`, body, {headers});
  }

  recuperarSenha(user) {
    return this.http.patch(`${this.uri}/user/public/recuperar-senha`, user);
  }

  userAuthenticated() {
    const auth = JSON.parse(localStorage.getItem('flint'));
    if (auth) {
      const dtExpires = new Date(auth.expires);
      if (new Date().getTime() > dtExpires.getTime()) {
        return false;
      }
      return true;
    }
    return false;
  }

}
