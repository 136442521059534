import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccessService } from 'app/services/access.service';

@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.css']
})
export class RecuperarSenhaComponent implements OnInit {

  recSenhaForm: FormGroup;

  constructor(private fb: FormBuilder, private as: AccessService, private router: Router) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.recSenhaForm = this.fb.group({
      username: ['', Validators.required]
    })
  }

  recuperarSenha() {
    this.as.recuperarSenha(this.recSenhaForm.value).subscribe((data: any) => {
      alert('Senha enviada com sucesso!');
      this.router.navigate(['/']);
    }, (err) => {
      alert('Ocorreu um erro ao enviar a senha!');
    });
  }

}
