import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/private/analise-agrotools', title: 'Análise Agrotools',  icon: 'dashboard', class: '' },
    { path: '/private/agrotools-detalhes-mudanca', title: 'Detalhes Mudança',  icon: 'dashboard', class: '' },
    { path: '/private/analise-contrato-territorio', title: 'Contrato x Território',  icon: 'dashboard', class: '' },
    { path: '/private/meta-milho', title: 'Meta Milho',  icon: 'dashboard', class: '' },
    { path: '/private/meta-biomassa', title: 'Meta Biomassa',  icon: 'dashboard', class: '' },
    { path: '/private/meta-nutricao', title: 'Meta Nutrição',  icon: 'dashboard', class: '' },
    { path: '/private/consolidado-sustentabilidade', title: 'Consolidado/Susten.',  icon: 'dashboard', class: '' },
];
export const ROUTESMILHO: RouteInfo[] = [
    { path: '/private/milho-painel-executivo', title: 'Painel Executivo',  icon: 'dashboard', class: '' },
//    { path: '/private/milho-analise-raiz', title: 'Análise Raiz',  icon: 'dashboard', class: '' },
//    { path: '/private/milho-insights', title: 'Insights',  icon: 'dashboard', class: '' },
    { path: '/private/milho-resumo-executados', title: 'Resumo Executados',  icon: 'dashboard', class: '' },
    { path: '/private/milho-resumo-monitorados', title: 'Resumo Monitorados',  icon: 'dashboard', class: '' },
    { path: '/private/milho-analise-executados', title: 'Análise Executados',  icon: 'dashboard', class: '' },
    { path: '/private/milho-analise-monitorados', title: 'Análise Monitorados',  icon: 'dashboard', class: '' },
    { path: '/private/milho-evolucao-passivos', title: 'Evolução Passivos',  icon: 'dashboard', class: '' },
    { path: '/private/milho-evolucao-passivos-cooperados', title: 'Ev. Passivos (Coop)',  icon: 'dashboard', class: '' },
    { path: '/private/milho-fornecedores', title: 'Fornecedores', icon: 'assignment_ind', class: ''},
    { path: '/private/milho-cooperados', title: 'Cooperados', icon: 'assignment_ind', class: ''},
    { path: '/private/milho-denom-material', title: 'Denom. Material', icon: 'compare_arrows', class: ''},
    { path: '/private/milho-zcp015', title: 'ZCP015', icon: 'done_all', class: ''},
    { path: '/private/milho-zmm036', title: 'ZMM036', icon: 'done_all', class: ''},
    { path: '/private/milho-ocorrencia-executados-list', title: 'Ocorrência (Exec.)', icon: 'record_voice_over', class: ''},
    { path: '/private/milho-ocorrencia-monitoramento-list', title: 'Ocorrência (Mon.)', icon: 'record_voice_over', class: ''}
];
export const ROUTESBIO: RouteInfo[] = [
    { path: '/private/bio-painel-executivo', title: 'Painel Executivo',  icon: 'dashboard', class: '' },
//    { path: '/private/bio-analise-raiz', title: 'Análise Raiz',  icon: 'dashboard', class: '' },
//    { path: '/private/bio-insights', title: 'Insights',  icon: 'dashboard', class: '' },
    { path: '/private/bio-resumo-executados', title: 'Resumo Executados',  icon: 'dashboard', class: '' },
    { path: '/private/bio-resumo-monitorados', title: 'Resumo Monitorados',  icon: 'dashboard', class: '' },
    { path: '/private/bio-analise-executados', title: 'Análise Executados',  icon: 'dashboard', class: '' },
    { path: '/private/bio-analise-monitorados', title: 'Análise Monitorados',  icon: 'dashboard', class: '' },
    { path: '/private/bio-evolucao-passivos', title: 'Evolução Passivos',  icon: 'dashboard', class: '' },
    { path: '/private/bio-evolucao-passivos-cooperados', title: 'Ev. Passivos (Coop)',  icon: 'dashboard', class: '' },
    { path: '/private/bio-fornecedores', title: 'Fornecedores', icon: 'assignment_ind', class: ''},
    { path: '/private/bio-cooperados', title: 'Cooperados', icon: 'assignment_ind', class: ''},
    { path: '/private/bio-denom-material-list', title: 'Denom. Material', icon: 'compare_arrows', class: ''},
    { path: '/private/bio-zcp015', title: 'ZCP015', icon: 'done_all', class: ''},
    { path: '/private/bio-zmm036', title: 'ZMM036', icon: 'done_all', class: ''},
    { path: '/private/bio-contratos-fisicos-list', title: 'Contratos Físicos', icon: 'done_all', class: ''},
    { path: '/private/bio-ocorrencia-executados-list', title: 'Ocorrência (Exec.)', icon: 'record_voice_over', class: ''},
    { path: '/private/bio-ocorrencia-monitoramento-list', title: 'Ocorrência (Mon.)', icon: 'record_voice_over', class: ''}
];
export const ROUTESDDG: RouteInfo[] = [
    { path: '/private/ddg-painel-executivo', title: 'Painel Executivo',  icon: 'dashboard', class: '' },
//    { path: '/private/ddg-analise-raiz', title: 'Análise Raiz',  icon: 'dashboard', class: '' },
//    { path: '/private/ddg-insights', title: 'Insights',  icon: 'dashboard', class: '' },
    { path: '/private/ddg-resumo-executados', title: 'Resumo Executados',  icon: 'dashboard', class: '' },
    { path: '/private/ddg-resumo-monitorados', title: 'Resumo Monitorados',  icon: 'dashboard', class: '' },
    { path: '/private/ddg-analise-executados', title: 'Análise Executados',  icon: 'dashboard', class: '' },
    { path: '/private/ddg-analise-monitorados', title: 'Análise Monitorados',  icon: 'dashboard', class: '' },
    { path: '/private/ddg-evolucao-passivos', title: 'Evolução Passivos',  icon: 'dashboard', class: '' },
    { path: '/private/ddg-clientes', title: 'Clientes', icon: 'assignment_ind', class: ''},
    { path: '/private/ddg-denom-material', title: 'Denom. Material', icon: 'compare_arrows', class: ''},
    { path: '/private/ddg-zcp015', title: 'ZCP015', icon: 'done_all', class: ''},
    { path: '/private/ddg-zsd032', title: 'ZSD032', icon: 'done_all', class: ''},
    { path: '/private/ddg-ocorrencia-executados-list', title: 'Ocorrência (Exec.)', icon: 'record_voice_over', class: ''},
    { path: '/private/ddg-ocorrencia-monitoramento-list', title: 'Ocorrência (Mon.)', icon: 'record_voice_over', class: ''}
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  menuItemsDDG: any[];
  menuItemsMilho: any[];
  menuItemsBio: any[];

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.menuItemsMilho = ROUTESMILHO.filter(menuItem => menuItem);
    this.menuItemsBio = ROUTESBIO.filter(menuItem => menuItem);
    this.menuItemsDDG = ROUTESDDG.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
